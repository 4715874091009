import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { REFERRAL_REGEX, CREW_REGEX } from '../globals'
import { setItem } from '../lib/asyncStorage'

//const supressLogging = process.env.NEXT_PUBLIC_SUPPRESS_HOOK_LOGGING === 'true'

export default function useInviteURL() {
  const router = useRouter()
  const query = router.query
  const refer = router.query.r
  const crew = router.query.c
  const [info, setInfo] = useState(query)

  useEffect(() => {
    const asyncStorage = async () => {
      const storageRefer = refer && (await setItem('refer', refer))
      const storageCrew = crew && (await setItem('crew', crew))
      if ((refer && REFERRAL_REGEX.test(refer)) || (storageRefer && REFERRAL_REGEX.test(storageRefer))) {
        //console.log('found refer code', refer || storageRefer)
        setInfo({ ...info, r: refer || storageRefer })
      }
      if ((crew && CREW_REGEX.test(crew)) || (storageCrew && CREW_REGEX.test(storageCrew))) {
        //console.log('found crew code', crew || storageCrew)
        setInfo({ ...info, c: crew || storageCrew })
      }
    }
    asyncStorage()
  }, [router.query])

  const setPath = (pathname, clearQuery) => {
    router.push({
      pathname,
      query: !clearQuery ? query : {},
    })
  }
  return [info, setPath]
}
