import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

import { FIREBASE_CONFIG, FUNCTIONS_URL_DEV } from '../globals'
import logErr, { logMsg } from '../lib/err'

///// AUTH

export const AUTH_USER_CHANGE = 'AUTH_USER_CHANGE'
export const AUTH_SEND_EMAIL = 'AUTH_SEND_EMAIL'
export const AUTH_ME_CHANGE = 'AUTH_ME_CHANGE'
export const AUTH_POSSES_CHANGE = 'AUTH_POSSES_CHANGE'
export const AUTH_PROFILE_CHANGE = 'AUTH_PROFILE_CHANGE'

export const AUTH_STATE_CHANGE = 'AUTH_STATE_CHANGE'
export const AUTH_STATE = {
  INIT: 'AUTH_STATE_INIT',
  INITTED: 'AUTH_STATE_INITTED',
  NONE: 'AUTH_STATE_NONE',
  LOADING: 'AUTH_STATE_LOADING',
  AUTHING: 'AUTH_STATE_AUTHING',
  AUTHED: 'AUTH_STATE_AUTHED',
  ERROR: 'AUTH_STATE_ERROR',
}

export const changeAuthState = (authState) => ({
  type: AUTH_STATE_CHANGE,
  authState,
})

export const changeAuthUser = (user) => ({
  type: AUTH_USER_CHANGE,
  user,
})

export function logOut() {
  return firebase.auth().signOut()
}

export const initFirebase = (auth, dispatch) => {
  if (auth.authState === AUTH_STATE.INIT) {
    const initTimeout = setTimeout(() => {
      const authError = new Error('auth init timeout')
      logErr(authError)
      dispatch({ type: AUTH_STATE_CHANGE, authState: AUTH_STATE.ERROR, error: authError })
    }, 10000)
    try {
      //console.log('Firebase INIT', FIREBASE_CONFIG)

      // firebase is stateful so we need to check if it already exists during development hot reload
      if (!firebase.apps || firebase.apps.length < 1) {
        firebase.initializeApp(FIREBASE_CONFIG)
        //console.log('Firebase initialized')

        // Firefox incognito throws an error, Chrome does not
        // const isSupported = firebase.analytics?.isSupported()
        // if (isSupported) {
        //   firebase.analytics()
        //   console.log('Analytics Initialized!')
        // }
      }
      //firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    } catch (err) {
      console.error('FAILED TO INIT FIREBASE', err)
      logErr(err, 'FAILED TO INIT FIREBASE', true)
      return
    }

    // Setting up remote config
    // try {
    //   const remoteConfig = firebase.remoteConfig();
    //   remoteConfig.settings = {
    //     minimumFetchIntervalMillis: 3600000,
    //   };
    //   await remoteConfig.fetchAndActivate()
    // } catch (e) {
    //   console.log('error setting up remote config', e)
    // }

    if (location.hostname === 'localhost' && process.env.USE_EMU) {
      console.log('USING EMULATOR SETTINGS')
      firebase.firestore().settings({
        host: 'localhost:8080',
        ssl: false,
      })
      firebase.functions().useFunctionsEmulator(FUNCTIONS_URL_DEV)
    } else {
      // firebase.firestore().settings({
      //   cacheSizeBytes: 1024 * 1024, // 1MB is the minimum; 40MB is the default
      // })
      firebase.firestore().settings({
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
      })

      // firebase
      //   .firestore()
      //   .enablePersistence({ PersistenceEnabled: true, synchronizeTabs: true })
      //   .then(() => {
      //     console.log('Woohoo! Multi-Tab Persistence!')
      //   })
      //   .catch(function (err) {
      //     if (err.code == 'failed-precondition') {
      //       // Multiple tabs open, persistence can only be enabled
      //       // in one tab at a a time.
      //       // ...
      //     } else if (err.code == 'unimplemented') {
      //       // The current browser does not support all of the
      //       // features required to enable persistence
      //       // ...
      //     }
      //   })
    }

    // authingTimeout

    const authingTimeout = setTimeout(async () => {
      const authError = new Error('authing timeout')
      console.log(authError)
      dispatch({ type: AUTH_STATE_CHANGE, authState: AUTH_STATE.ERROR, error: authError })
      logMsg('authing timeout')
    }, 10000)

    // onAuthStateChanged

    firebase.auth().onAuthStateChanged(
      (user) => {
        clearTimeout(authingTimeout)
        console.log('auth state changed', user)
        if (user) {
          // User is signed in.
          dispatch({ type: AUTH_STATE_CHANGE, authState: AUTH_STATE.AUTHED, error: null })
          dispatch({ type: AUTH_USER_CHANGE, user })
        } else {
          // No user is signed in.
          dispatch({ type: AUTH_STATE_CHANGE, authState: AUTH_STATE.NONE, error: null })
          dispatch({ type: AUTH_USER_CHANGE, user: null })
        }
      },
      (err) => {
        console.error('auth error', err)
        logErr(err, 'Auth Error')
      }
    )

    // firebase.auth().onIdTokenChanged(
    //   (user) => {
    //     console.log('auth token changed', user)
    //   },
    //   (err) => {
    //     console.log('auth token change error', err)
    //   }
    // )

    //console.log('initted')
    clearTimeout(initTimeout)
    dispatch({
      type: AUTH_STATE_CHANGE,
      authState: AUTH_STATE.INITTED,
      firebase,
    })

    //console.log('authing')
    dispatch({ type: AUTH_STATE_CHANGE, authState: AUTH_STATE.AUTHING })
  }
}

///// ME

export const ME_USER_CHANGE = 'ME_USER_CHANGE'
export const ME_USER_LOADING = 'ME_USER_LOADING'
export const ME_RESET = 'ME_RESET'
export const ME_USER_MERGE = 'ME_USER_MERGE'

export const changeMe = (user) => ({
  type: ME_USER_CHANGE,
  user,
})

export const mergeUser = (user) => ({
  type: ME_USER_MERGE,
  user,
})

export const resetMe = () => ({
  type: ME_RESET,
})

///// POSSES

export const POSSES_MERGE = 'POSSES_MERGE'
export const POSSES_REMOVE_ID = 'POSSES_REMOVE_ID'
export const POSSES_RESET = 'POSSES_RESET'

export const mergePosses = (posses) => ({
  type: POSSES_MERGE,
  posses,
})

export const resetPosses = () => ({
  type: POSSES_RESET,
})

export const deletePosseID = (id) => ({
  type: POSSES_REMOVE_ID,
  id,
})

///// PROFILES

export const PROFILES_MERGE = 'PROFILES_MERGE'
export const PROFILES_REMOVE_ID = 'PROFILES_REMOVE_ID'
export const PROFILES_RESET = 'PROFILES_RESET'

export const mergeProfiles = (profiles) => ({
  type: PROFILES_MERGE,
  profiles,
})

export const resetProfiles = () => ({
  type: PROFILES_RESET,
})

export const deleteProfileID = (id) => ({
  type: PROFILES_REMOVE_ID,
  id,
})

///// INVITES

export const INVITES_MERGE = 'INVITES_MERGE'
export const INVITES_REMOVE_ID = 'INVITES_REMOVE_ID'
export const INVITES_RESET = 'INVITES_RESET'

export const mergeInvites = (invites) => ({
  type: INVITES_MERGE,
  invites,
})

export const resetInvites = () => ({
  type: INVITES_RESET,
})

export const deleteInviteID = (id) => ({
  type: INVITES_REMOVE_ID,
  id,
})

////// UI

export const UI_TOGGLE_HAMBURGER = 'UI_TOGGLE_HAMBURGER'
export const UI_OPEN_HAMBURGER = 'UI_OPEN_HAMBURGER'
export const UI_CLOSE_HAMBURGER = 'UI_CLOSE_HAMBURGER'

export const UI_SET_AMAZON_SECTION = 'UI_SET_AMAZON_SECTION'
export const UI_GOTO_FAQ = 'UI_GOTO_FAQ'

export const UI_SHOW_PLEDGE_REMINDER = 'UI_SHOW_PLEDGE_REMINDER'
export const UI_PLEDGE_REMINDER_DISMISSED = 'UI_PLEDGE_REMINDER_DISMISSED'
export const UI_APP_ERROR = 'UI_APP_ERROR'
export const UI_GOTO_AFTER_LOGIN = 'UI_GOTO_AFTER_LOGIN'
export const UI_LOADING_POSSES = 'UI_LOADING_POSSES'
export const UI_LOADING_PROFILES = 'UI_LOADING_PROFILES'
export const UI_ENABLE_LOGIN_REDIRECT = 'UI_ENABLE_LOGIN_REDIRECT'

export const UI_STATE = 'UI_STATE'

export const gotoAfterLogin = (path) => {
  return {
    type: UI_GOTO_AFTER_LOGIN,
    path,
  }
}

export const loadingPosses = (loadingPosses) => {
  return {
    type: UI_LOADING_POSSES,
    loadingPosses,
  }
}

export const loadingProfiles = (loadingProfiles) => {
  return {
    type: UI_LOADING_PROFILES,
    loadingProfiles,
  }
}

export const updateUIState = (state) => {
  console.log('MERGE UI STATE', state)
  return {
    type: UI_STATE,
    state,
  }
}

export const enableLoginRedirect = (enable) => {
  return {
    type: UI_ENABLE_LOGIN_REDIRECT,
    enable,
  }
}
